import GoldRushBg from '../assets/images/gold-rush-bg.jpg';
import GoldRushLogo from '../assets/images/gold-rush-logo.png';
import Sticker1 from '../assets/images/sticker-1.png';
import Sticker2 from '../assets/images/sticker-2.png';
import Sticker3 from '../assets/images/sticker-3.png';
import FirstPlace from '../assets/images/first-place.png';
import SecondPlace from '../assets/images/second-place.png';
import SocialTelegram from '../assets/images/social-telegram.png';
import SocialTwitter from '../assets/images/social-twitter.png';
import ThirdPlace from '../assets/images/third-place.png';
import People from '../assets/images/people.png';
import MuskLogoRush from '../assets/images/musk-logo-rush.png';
import SensoryOverload from '../assets/images/sensory-overload.jpg';
import SolarBg from '../assets/images/musk-rush-solar-bg.jpg';

export const Images = {
  GoldRushBg,
  GoldRushLogo,
  Sticker1,
  Sticker2,
  Sticker3,
  FirstPlace,
  SecondPlace,
  SocialTelegram,
  SocialTwitter,
  SensoryOverload,
  ThirdPlace,
  People,
  MuskLogoRush,
  SolarBg,
};
