import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '../components/Layout';
import { Images } from '../constants/Images';
import { QueryProps } from '../interfaces';

const Container = styled.div`
  display: grid;
  padding: 0 20px 100px 20px;
  background: #aa2318;
`;

const Content = styled.div`
  display: grid;
  position: relative;
  padding: 20px 0 20px;
  width: 100%;
  justify-self: center;
  @media (min-width: 768px) {
    width: 800px;
  }
  @media (min-width: 1280px) {
    width: 1280px;
  }
`;

const Title = styled.h2`
  font-family: 'Lato';
  text-transform: uppercase;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  z-index: 1;
  @media (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const TypeBlock = styled.div`
  @media (min-width: 768px) {
    grid-column: 2 / span 4;
  }
`;

const TypeHtml = styled.div`
  font-family: 'Lato';
  color: #f4f4f4;
  line-height: 40px;
  span {
    color: #fff;
  }
  a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h3 {
    margin: 50px 0;
    text-align: right;
    border-bottom: 1px solid #fff;
  }
  h3 a {
    color: #aa2318;
    background: #fff;
    padding: 10px 20px;
  }
  h2 {
    font-weight: normal;
  }
`;

const PeopleImage = styled.img`
  position: fixed;
  bottom: 0;
  display: block;
  width: 100%;
  left: 0;
  height: auto;
  overflow: hidden;
`;

export default ({ data }: QueryProps) => {
  const node = data.allMarkdownRemark.nodes[0];
  const html = node.html ?? '';
  return (
    <Layout>
      <Helmet title="Terms" />
      <Container>
        <Content>
          <ContentGrid>
            <TypeBlock>
              <Title>$MUSK GOLD RUSH</Title>
              <TypeHtml dangerouslySetInnerHTML={{ __html: html }} />
            </TypeBlock>
          </ContentGrid>
          <PeopleImage src={Images.People} alt="" />
        </Content>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { title: { eq: "Sticker Contest" } } }
    ) {
      nodes {
        html
      }
    }
  }
`;
